@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.detail-header {
  margin-top: 20px;
  @mixin ellipsiss($row: 2) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @mixin ellipsis() {
    text-overflow: ellipsis; //溢出省略号显示
    white-space: nowrap;
    overflow: hidden;
  }

  $width: 680px;

  .flex {
    display: flex;
    align-items: center;
  }
  .top-box {
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    padding: 0 0 24px 0;

    .back {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 16px;
      left: 20px;
      z-index: 13;
    }

    .banner {
      width: $width;
      height: 382px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .top-icon {
      width: $width;
      height: 382px;
    }

    .gmae-info {
      width: $width;
      box-sizing: border-box;
      padding: 24px 24px 0 24px;
      display: flex;
      justify-content: space-between;

      .gmae-icon__box {
        width: 119px;
        position: relative;
        .game-icon {
          width: 119px;
          height: 119px;
          border-radius: 28px;
        }

        .game-icon__ranking {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          z-index: 10;
        }

        .game-icon__bg {
          width: 127px;
          height: 32px;
          position: absolute;
          bottom: -3px;
          left: -4px;
          z-index: 11;
          background-size: cover;
          background-position: 100% 100%;
          background-repeat: no-repeat;
        }

        .game-icon__info {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 0 6px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .game-icon__info-desc {
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              word-break: break-all;
              font-size: 12px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              line-height: 17px;
            }
          }
        }
      }

      .info {
        max-width: 380px;
        padding: 0 26px 0 16px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          width: 100%;
          @include ellipsis();
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 33px;
        }

        .tags {
          flex-wrap: wrap;
          .tag {
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #cccccc;
            padding: 0 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            margin: 9px 8px 0 0;
            cursor: pointer;
          }
        }

        .download-btn {
          cursor: pointer;
          min-width: 152px;
          height: 38px;
          margin-right: 12px;
          background: #2496ff;
          border-radius: 23px;
          justify-content: center;

          img {
            width: 16px;
            height: 18px;
          }

          span {
            margin-left: 8px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
        }
        .yellow {
          background: #ffa142;
        }
        .off {
          color: #999999;
          background: #ededed;
        }

        .download-desc {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          text-align: center;
        }
      }

      .evaluation {
        justify-content: center;
        .game-level {
          box-sizing: border-box;
          justify-content: center;
          width: 88px;
          height: 88px;
          .star {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 25px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  }

  .up-service {
    cursor: pointer;
    margin: 24px 24px 0 24px;
    min-height: 32px;
    background: rgba(244, 245, 246, 0.6);
    border-radius: 16px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;

    &__desc {
      flex: 1;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      padding: 6px 4px;
      max-width: 565px;
      @include ellipsis();

      &-time {
        width: 150px;
      }

      &-con {
        flex: 1;
      }
    }
    &__icon {
      width: 9px;
    }
  }

  // 吸顶
  .header-fixed {
    width: 680px;
    position: fixed;
    top: 0;
    z-index: 20;
    padding-top: 76px;
    background-color: #f8f8f8;
    .box {
      padding: 24px;
      background-color: #fff;
      .back {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
      .game-info {
        margin-left: 15px;
        .gmae-icon__box {
          width: 64px;
          position: relative;
          .game-icon {
            width: 64px;
            height: 64px;
            border-radius: 16px;
          }

          .game-icon__ranking {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            z-index: 10;
          }

          .game-icon__bg {
            width: 70px;
            height: 18px;
            position: absolute;
            bottom: -3px;
            left: -3px;
            z-index: 11;
            background-size: cover;
            background-position: 100% 100%;
            background-repeat: no-repeat;
          }

          .game-icon__info {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 0 6px;
            box-sizing: border-box;
            padding-top: 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .game-icon__info-desc {
              top: 2px;
              position: absolute;
              width: 80px;
              left: -5px;
              height: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                word-break: break-all;
                font-size: 6px;
                height: 16px;
                line-height: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                transform: scale(0.5);
              }
            }
          }
        }

        .info {
          padding: 0 40px 0 16px;
          box-sizing: border-box;
          .name {
            width: 352px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
            @include ellipsis();
          }
          .tags {
            flex-wrap: wrap;
            .tag {
              background: #ffffff;
              border-radius: 2px;
              border: 1px solid #cccccc;
              padding: 0 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 17px;
              margin: 9px 8px 0 0;
              cursor: pointer;
            }
          }
        }
      }
      .download-btn {
        cursor: pointer;
        width: 110px;
        height: 28px;
        background: linear-gradient(
          90deg,
          #4bc7ff 0%,
          #2496ff 100%,
          #2496ff 100%
        );
        border-radius: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        text-align: center;
      }
    }
    .up-service {
      margin-left: 0;
    }
  }
}

.more-tag-btn {
  width: 16px;
  height: 19px;
  margin: 9px 8px 0 0;
  display: flex;
  cursor: pointer;
}
