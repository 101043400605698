@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


















































.info-container {
  width: auto;
  height: 112px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  padding: 26px 24px 22px;
  box-sizing: border-box;
  .img-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 16px;
    overflow: hidden;
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
    margin-bottom: 7px;
  }
  .links {
    display: flex;
    img {
      margin-left: 4px;
      margin-right: 16px;
      padding-top: 2px;
    }
    a {
      font-size: 12px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.6);
      line-height: 17px;
      display: flex;
    }
  }
}
