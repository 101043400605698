@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: 12px;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 55px;
}
::v-deep .van-tab {
  margin-left: 32px;
  padding: 0;
}
::v-deep .van-divider {
  margin: 0;
  margin-top: -2px;
  border-color: $DividerColor;
}
::v-deep .van-tabs__line {
  width: 20px;
}
.game-comments-page {
  $width: 680px;

  .flex {
    display: flex;
    align-items: center;
  }

  .ellipsiss {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .header {
    img {
      width: 24px;
    }
    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-left: 17px;
    }
  }

  .content {
    width: $width;
    margin-top: 20px;
    background-color: #fff;

    .tabs-box {
      width: 268px;
      height: 55px;

      .line {
        width: 680px;
        height: 1px;
        background: #eeeeee;
      }
    }

    .evaluation {
      padding: 24px;
      .game-evaluate-grade {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
      }
      .box {
        padding-left: 24px;
        .img {
          width: 100px;
          height: 100px;
        }
        .ratio {
          margin-left: 36px;

          .stars {
            height: 12px;
            margin: 4px 0;
            .star {
              margin-right: 8px;
              width: 12px;
              height: 12px;
              display: flex;
              img {
                width: 12px;
                height: 12px;
              }
            }
          }

          .progress-bg {
            margin-left: 8px;
            width: 364px;
            height: 4px;
            background: #f0f4f7;
            border-radius: 2px;
            overflow: hidden;
            .progress {
              height: 100%;
              background: #2496ff;
              border-radius: 2px;
            }
          }
        }
      }

      .btns {
        margin-top: 24px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 22px;
        justify-content: space-between;
        .btn-share {
          cursor: pointer;
          width: 304px;
          height: 46px;
          background: #ffffff;
          border-radius: 23px;
          border: 1px solid #2496ff;
          justify-content: center;
          color: #2496ff;

          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }

        .btn-comment {
          cursor: pointer;
          width: 304px;
          height: 46px;
          background: linear-gradient(
            90deg,
            #4bc7ff 0%,
            #2496ff 100%,
            #2496ff 100%
          );
          border-radius: 23px;
          color: #fff;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .comments {
    padding: 16px 24px 24px 24px;
    &-header {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
      }

      .filter-tool {
        position: relative;

        .default,
        .filter {
          cursor: pointer;
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-right: 4px;
          }
          img {
            width: 8px;
            height: 8px;
          }
        }
        .filter {
          margin: 0 16px 0 32px;
        }

        .filter-list {
          z-index: 100;
          position: absolute;
          right: 0;
          top: 24px;
          width: 99px;
          background: #ffffff;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
            0px 6px 16px 0px rgba(0, 0, 0, 0.08),
            0px 3px 6px -4px rgba(0, 0, 0, 0.12);
          border-radius: 4px;

          .filter-item {
            cursor: pointer;
            width: 99px;
            height: 36px;
            background: #fff;
            text-align: center;
            line-height: 36px;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            &:hover {
              background-color: rgba(36, 150, 255, 0.1) !important;
            }
          }
        }
      }
    }

    .list {
      .item {
        width: 632px;
        padding: 16px;
        background: #fafafa;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 16px;
        .userinfo {
          display: flex;
          width: 100%;
          .avatar-wrapper {
            width: 32px;
            height: 32px;
            position: relative;
            margin-right: 8px;
            .avatar {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              overflow: hidden;
              border: 1px solid rgba(0, 0, 0, 0.1);
              display: flex;
              box-sizing: border-box;
              img {
                width: 100%;
              }
            }
            .auth {
              position: absolute;
              z-index: 2;
              bottom: 0;
              right: 0;
            }
          }
          .info {
            flex: 1;
            .name {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #666666;
              line-height: 17px;
              display: flex;
              align-items: center;
            }
            .star-and-time {
              .star {
                display: flex;
                img {
                  width: 12px;
                  height: 12px;
                }
              }
              .time {
                margin-left: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #cccccc;
                line-height: 17px;
              }
            }
          }
        }
        .item-foot {
          margin-top: 16px;
          justify-content: space-between;
          .device {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #cccccc;
            line-height: 17px;
            img {
              width: 12px;
              height: 16px;
            }

            .phone,
            .version {
              margin-left: 10px;
            }
          }
          .favo {
            .comm,
            .like {
              cursor: pointer;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
              img {
                width: 16px;
                height: 16px;
              }
              span {
                margin-left: 4px;
              }
            }
            .comm {
              margin-right: 32px;
            }
          }
        }
        .reply {
          border-top: 1px solid #eee;
          margin-top: 24px;
          padding: 24px 0 12px 0;

          .user-msg {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 22px;
            .username {
              color: #666666;
            }
            .msg {
              color: #333333;
            }
          }

          .more-reply {
            cursor: pointer;
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2496ff;
            line-height: 20px;

            img {
              width: 9px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
