@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";










































.layout-container {
  min-height: 100vh;
  box-sizing: border-box;
  transition: 0.2s;
  background-color: $BgColor;
  padding-top: 56px;

  header {
    height: 56px;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 105;
  }

  main {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    max-width: 1350px;
    margin: 0 auto;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
    .menu {
      height: 100%;
    }
  }
}
