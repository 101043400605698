@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





































































































































































































































































.game-list-container {
  width: 1040px;
  display: flex;
  overflow: hidden;
  .game-card {
    margin-bottom: 20px;
    &:first-child {
      margin-top: 20px;
    }
  }
  .main {
    flex: 1;
  }

  .aside {
    width: 340px;
    margin-left: 20px;
  }
}
