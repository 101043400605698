@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































.scan {
  display: flex;
  align-items: center;
  padding: 26px;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 4px;
  cursor: pointer;

  .flex {
    display: flex;
    align-items: center;
  }

  &-left {
    flex: 1;

    &-img {
      width: 112px;
      width: 112px;
      height: 40px;
      margin-bottom: 16px;
    }

    &-desc {
      p {
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: $TextColor3rd;
      }
      &-arrow {
        width: 12px;
        height: 12px;
      }
    }
  }

  &-right {
    position: relative;
    height: 76px;
    overflow: hidden;
    &-img-down {
      width: 76px;
      height: 76px;
    }

    &-img-up {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 64px;
      height: 64px;
    }
  }
}
