@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


























































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.content {
  position: relative;
  width: 340px;
  border-radius: 4px;
  overflow: hidden;

  .flex {
    display: flex;
    align-items: center;
  }

  @mixin ellipsiss($row: 2) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @mixin ellipsis() {
    text-overflow: ellipsis; //溢出省略号显示
    white-space: nowrap;
    overflow: hidden;
  }

  .hot-bbs {
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 4px;

    .header {
      box-sizing: border-box;
      width: 340px;
      height: 56px;
      background: rgba(255, 255, 255, 0);

      @extend .flex;
      justify-content: space-between;
      padding: 0 24px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $TextColor1st;
      }

      div {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2496ff;
      }
    }

    .list {
      padding: 8px 0;
      .item {
        box-sizing: border-box;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 16px 24px;

        img {
          border-radius: 50%;
          width: 48px;
          height: 48px;
          border: 1px solid $BorderColor;
          margin-right: 16px;
        }

        .info {
          flex: 1;
          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $TextColor1st;
            min-width: 100px;
            @include ellipsis();
          }
          p:nth-child(2) {
            margin-top: 8px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            min-width: 100px;
            line-height: 12px;
            color: $TextColor3rd;
            @include ellipsiss();
          }
        }
      }
    }
  }
}
