@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



































































































































































div.dplayer-wrap {
  font-size: 10px;
}
.ghzs-player {
  width: 100%;
  height: auto;
  background: #d8d8d8;
  & div.dplayer {
    height: 100%;
  }
}
::v-deep .dplayer-volume {
  display: inline-block !important;
}
