@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";















































































































































































































































::v-deep .swiper-container .swiper-wrapper {
  -webkit-transition-timing-function: linear; /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
::v-deep .swiper-container {
  width: 680px;
  .swiper-slide {
    width: auto;
  }
}
.column-card {
  width: 680px;
  &.manual {
    height: 247px;
  }
  padding: 24px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 20px;

  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
    }
    .btn {
      display: flex;
      align-items: flex-end;
    }
    .btn-text {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #2496ff;
      line-height: 22px;
    }
    .btn-text,
    .btn-more {
      display: block;
    }
  }
  .list {
    display: flex;
    overflow: hidden;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 24px;
      .time-wrap {
        display: flex;
        align-items: baseline;
        height: 20px;
        .time {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-right: 2px;
          line-height: 20px;
        }
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-top: 8px;
        height: 20px;
      }
      .time-line {
        height: 36px;
        width: 100%;
        position: relative;
        .dot {
          width: 8px;
          height: 8px;
          background: #cccccc;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 10px;
          transform: translateX(-50%);
          z-index: 2;
        }
        .dot.active {
          background: #2496ff;
        }
        .line {
          width: 134px;
          background: #ebebeb;
          height: 2px;
          position: absolute;
          left: 0;
          top: 13px;
        }
      }
      .image {
        width: 104px;
        height: 104px;
        border-radius: 26px;
        margin-bottom: 16px;
        overflow: hidden;
        font-size: 12px;
        display: block;
      }
      .game-name {
        max-width: 96px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        @include ellipsis();
      }
    }
  }
}
.auto {
  padding: 0;
  padding-bottom: 8px;
  .title-row {
    padding-left: 24px;
    padding-top: 24px;
  }
  .image {
    display: block;
    width: 104px;
    height: 104px;
    border-radius: 26px;
    margin-bottom: 16px;
    overflow: hidden;
    font-size: 12px;
  }
  .auto-wrapper {
    overflow: hidden;
    .auto-row1,
    .auto-row2 {
      display: flex;
      & > a {
        margin-right: 16px;
      }
    }
    .auto-row2 {
      margin-left: -59px;
    }
  }
}
