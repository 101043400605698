@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
















































.preview-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);

  .content {
    position: relative;

    .main-img {
      max-height: calc(100vh - 106px);
      max-width: calc(100vw - 106px);
      border-radius: 4px;
    }

    .close-icon {
      position: absolute;
      width: 44px;
      height: 44px;
      right: -52px;
      top: -48px;
    }
  }
}

.fade-enter-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
