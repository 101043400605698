.detail-relate-game {
  padding: 24px 24px 16px 24px;
  .flex {
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .content {
    padding: 16px 0 0 0;
    flex-wrap: wrap;
    width: 632px;
    .game-info {
      cursor: pointer;
      width: 316px;
      .avatar {
        width: 64px;
        height: 64px;
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 64px;
          height: 64px;
        }
      }
      .info {
        flex: 1;
        margin-left: 16px;
        .name {
          width: 236px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 33px;
          text-overflow: ellipsis; //溢出省略号显示
          white-space: nowrap;
          overflow: hidden;
        }
        .tags {
          margin-top: 10px;
          width: 216px;
          flex-wrap: wrap;
          .tag {
            margin-right: 8px;
            background: #e7f3ff;
            border-radius: 2px;
            padding: 4px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 17px;
          }
        }
      }
    }
    .view-more {
      cursor: pointer;
      margin-top: 24px;
      width: 632px;
      height: 46px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px solid #eeeeee;

      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2496ff;
      text-align: center;
      line-height: 48px;
    }
  }
}
