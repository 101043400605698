@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































.novel-hot {
  width: 340px;
  max-height: 392px;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  .title-row {
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    padding-left: 24px;
    border-bottom: 1px solid #eee;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 24px 0;
    margin-top: 8px;

    .item {
      height: 36px;
      background: #f0f4f7;
      border-radius: 20px;
      margin-right: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #7690ad;
      display: flex;
      align-items: center;
      padding-left: 4px;
      padding-right: 12px;
      margin-bottom: 16px;
      .icon {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        overflow: hidden;
        margin-right: 8px;
      }
      .text {
        max-width: 240px;
        @include ellipsis();
      }
    }
  }
}
