@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";













































































































::v-deep .van-popup {
  width: 400px;
  height: 550px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 10001 !important;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10001 !important;
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-tab:first-child {
  margin-right: 24px;
}
::v-deep .van-tabs--line {
  .van-tabs__wrap {
    height: 56px;
  }
  .van-tabs__line {
    width: 20px;
  }
}
.gameDialog {
  .title-wrapper {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: $TextColor1st;
    }
    .close-icon {
      width: 16px;
      height: 16px;
    }
  }

  .search-wrap {
    margin: 16px 24px 8px;
  }
  .no-game-item {
    padding: 8px 0;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    padding: 16px 24px;
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
  }
  .game-list {
    max-height: 390px;
    overflow-y: auto;
    .game-item {
      height: 80px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        width: 48px;
        height: 48px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
        margin-right: 16px;
        margin-left: 24px;
      }
      .name {
        max-width: 288px;
        line-height: 16px;
        @include ellipsis();
      }
      &:hover {
        background: #fafafa;
      }
    }
  }
}

element::-webkit-scrollbar {
  display: none;
}
