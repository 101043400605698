@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";









































































































































.operation-wrapper {
  width: 340px;
  height: 84px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  .btn {
    flex: 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
    .icon {
      width: 48px;
      height: 48px;
    }
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
    }
  }
}
