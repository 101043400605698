@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


























































































































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: 12px;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 55px;
}
::v-deep .van-tab {
  margin-left: 32px;
  padding: 0;
}
::v-deep .van-divider {
  margin: 0;
  margin-top: -2px;
  border-color: $DividerColor;
}
::v-deep .van-tabs__line {
  width: 20px;
}

::v-deep .ghzs-player {
  height: 100%;
  & div.dplayer {
    height: 100%;
  }
}

.game-detail-page {
  height: 100%;
  padding: 0 0 8px 0;
  box-sizing: border-box;

  @mixin ellipsiss($row: 2) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @mixin ellipsis() {
    text-overflow: ellipsis; //溢出省略号显示
    white-space: nowrap;
    overflow: hidden;
  }

  $width: 680px;

  .flex {
    display: flex;
    align-items: center;
  }

  .header {
    background-color: transparent;
    img {
      cursor: pointer;
      width: 24px;
    }
    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-left: 17px;
    }
  }

  .content {
    width: $width;
    margin-top: 20px;
    background-color: #fff;

    .fixed {
      position: fixed;
      top: 164px;
      width: 680px;
      z-index: 11;
      background-color: #fff;
    }
    .tabs-bg {
      height: 20px;
      background-color: #f8f8f8;
    }

    .tabs-box {
      width: 268px;
      height: 55px;

      .line {
        width: 680px;
        height: 1px;
        background: #eeeeee;
      }
    }
  }
}
