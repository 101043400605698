@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
































































































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.game-card {
  width: 680px;
  height: 475px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  overflow: hidden;
  display: block;
  .banner {
    width: 100%;
    height: 382px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .info {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    .left-top {
      display: flex;
      align-items: center;
    }
    .name {
      max-width: 288px;
      height: 33px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-right: 8px;
      @include ellipsis();
    }
    .tags {
      display: flex;
      align-items: center;
    }
    .tag {
      height: 18px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #cccccc;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      text-align: center;
      margin-right: 8px;
      padding: 0 4px;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 8px;
      max-width: 500px;
      @include ellipsis();
    }
    .star {
      display: flex;
      align-items: center;
    }
    .score {
      font-size: 32px;
      font-weight: 600;
      color: #2496ff;
      margin-left: 8px;
    }
  }
}
