@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";










































.backtop {
  .main {
    z-index: 10;
    position: fixed;
    right: 40px;
    .icon {
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
