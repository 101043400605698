@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





































































































































































































































































































































.upload-video-container {
  .entry-box {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    .entry-card {
      width: 100%;
      height: 354px;
      background: #fafafa;
      border-radius: 4px;
      box-sizing: border-box;
      border: 2px dashed #eeeeee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tip-text {
        font-size: 18px;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
        margin-top: 32px;
        margin-bottom: 24px;
      }
      .entry-btn {
        width: 200px;
        height: 40px;
        background: #2496ff;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 16px;
        text-align: center;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .limit-tip {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }
  }
  .video-box {
    height: 354px;
    background: #fafafa;
    overflow: hidden;
    border-radius: 4px;
    .pending-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 121px;
      .pending-tip {
        height: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        line-height: 16px;
        margin: 16px 0 12px;
      }
      .pending-progress {
        width: 200px;
        height: 4px;
        background: #f0f4f7;
        border-radius: 2px;
        overflow: hidden;
        .progress-val {
          width: 0px;
          height: 4px;
          background: #2496ff;
          border-radius: 2px;
        }
      }
    }
    .fail-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 121px;
      .fail-tip {
        height: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        line-height: 16px;
        margin: 16px 0 24px;
      }
      .fail-btn {
        width: 88px;
        height: 32px;
        border-radius: 20px;
        border: 1px solid #eeeeee;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        cursor: pointer;
      }
    }
    .success-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .success-btn {
        width: 88px;
        height: 32px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
        text-align: center;
        position: absolute;
        bottom: 16px;
        cursor: pointer;
      }
    }
  }
}
