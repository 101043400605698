@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
































.not-found-data {
  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 160px;
      height: 160px;
    }

    .name {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $TextColor3rd;
      line-height: 16px;
      padding: 0 16px;
    }

    .desc {
      margin-top: 8px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $TextColor;
      line-height: 12px;
      padding: 0 16px;
      font-size: 12px;
    }
  }
}
