@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























.no-more-data {
  .nmd-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  @extend .nmd-flex;

  .main {
    flex: 1;
    height: 40px;
    background: #fafafa;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $TextColor3rd;
    @extend .nmd-flex;
  }

  &[bg="white"] .main {
    background-color: #fff;
  }
}
