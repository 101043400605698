@mixin contentStyle {
    h1 {
        font-size: 22px;
        font-weight: bold;
        color: #4a4a4a;
        line-height: 30px;
        margin: 8px 0;
      }
    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 28px;
      margin: 8px 0;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 26px;
      margin: 8px 0;
    }
    h4 {
      font-size: 17px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 25px;
      margin: 8px 0;
    }
    b {
      font-size: 16px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 28px;
      font-weight: bold;
    }
    i {
      font-style: italic;
    }
    blockquote {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 0px;
      margin-right: 0px;
      padding: 8px 16px;
      font-size: 16px;
      line-height: 28px;
      color: #4a4a4a;
      position: relative;
      border-left: none;
      word-wrap: break-word;
      font-weight: bold;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        height: calc(100% - 24px);
        width: 2px;
        background: #2496ff;
        display: block;
        z-index: 1;
        transform: translateY(-50%);
      }
    }
    img {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.03);
      box-sizing: border-box;
      max-width: 100%;
    }
    .placeholder-image-container {
      img {
        border: none;
      }
    }
    /** 插入引用开始 */
    a.answer,
    a.community_article,
    a.game,
    a.video {
      width: 360px;
      margin: 0;
      display: block;
      text-decoration: none;
      color: #444444;
      border: 1px solid #eeeeee;
      height: 80px;
      box-sizing: border-box;
      background: #f2f5f7;
      border-radius: 4px;
      margin: 15px auto;
      position: relative;
    }
    a.answer > .flex-container,
    a.community_article > .flex-container, 
    a.video > .flex-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      padding: 10px 12px;
      position: relative;
      margin: 6px 0;
    }
  
    a.game > .flex-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 16px;
      margin: 0;
      position: relative;
      height: 80px;
      box-sizing: border-box;
    }
    a.game > .flex-container::after {
      content: "详情";
      display: block;
      font-size: 12px;
      line-height: 12px;
      width: 24px;
      box-sizing: border-box;
      padding: 0;
      margin-right: 5px;
      right: 36px;
      position: absolute;
      color: $TextColor3rd;
    }
    a.game > .flex-container::before {
      display: none;
    }
  
    a.answer > .flex-container .content-right,
    a.community_article > .flex-container .content-right,
    a.game > .flex-container .content-right, 
    a.video > .flex-container .content-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      width: calc(100vw - 120px);
      margin-left: 0;
      .content-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 230px;
      }
    }
    a.answer > .flex-container .content-right .contents,
    a.community_article > .flex-container .content-right .contents,
    a.video > .flex-container .content-right .contents {
      color: #999999;
      font-size: 12px !important;
      line-height: 16px;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
    a.game > .flex-container .content-right .tags {
      margin: 0;
      display: flex;
      label {
        white-space: nowrap;
        border: #2496ff 1px solid;
        padding: 2px 4px;
        margin-right: 8px;
        color: #99a5ad;
        border-radius: 2px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        box-sizing: border-box;
        background: #e6eaed;
        border: none;
      }
    }
    // img-left 以前旧版在左边，新设计图在右边
    a.answer > .flex-container .img-left,
    a.community_article > .flex-container .img-left,
    a.game > .flex-container .img-left, 
    a.video > .flex-container .img-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      background-color: none;
      border-radius: 3px;
      border: none;
      margin-left: 16px;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }
    a.game > .flex-container .img-left > img {
      border-radius: 12px;
    }
    a.game > .flex-container .img-left {
      margin-left: 0;
      margin-right: 16px;
      border-radius: 12px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      &:after {
        content: "";
        pointer-events: none;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        border-color: rgba(0, 0, 0, 0.1);
      }
    }
    a.game > .flex-container .content-right {
      .content-title {
        margin-bottom: 6px;
      }
    }
  
    a.answer > .flex-container::after,
    a.community_article > .flex-container::after,
    a.video > .flex-container::after,
    a.game > .flex-container::after,
    a.answer > .flex-container::before,
    a.community_article > .flex-container::before,
    a.video > .flex-container::before,
    a.game > .flex-container::before {
      display: none;
    }
    /** 插入引用结束 */
   

    .dplayer.dplayer-fulled.dplayer.dplayer-fulled {
      top: -10px;
      height: calc(100% + 10px);
    }
  
    .dplayer.dplayer-fulled {
      position: fixed;
    }
  
    .insert-video-container {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      height: 193px;
      margin: 10px 0;
    }
  
    .video-poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: black;
      border-radius: 6px;
    }
  
    .video-poster img.poster.poster {
      width: 100%;
      height: 193px;
      border-radius: 4px;
      margin: initial;
      object-fit: cover;
      margin: 0;
    }
  
    .video-js {
      width: 100%;
      height: 225px;
      border-radius: 6px;
      display: none;
    }
  
    .insert-video-container .vjs-control-bar {
      border-radius: 0 0 6px 6px;
    }
  
    .insert-video-container .duration {
      position: absolute;
      right: 10px;
      bottom: 10px;
      background: rgba(0, 0, 0, 0.6);
      padding: 2px 8px;
      z-index: 11;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 16px;
      box-sizing: border-box;
      font-size: 10px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.8);
    }
  
    .start-play {
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      background-color: rgba(115, 133, 159, 0.5);
      z-index: 11;
      background: url(~@/assets/images/按钮-媒体播放按钮.png) center no-repeat;
      background-size: 40px 40px;
    }
  
    .start-play .play-inner-button {
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left: 15px solid white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-10px, -20px, 0);
      display: none;
      background-size: 48px 48px;
    }
  
    .status-tip {
      width: 100%;
      position: absolute;
      bottom: 0;
      text-align: center;
      font-size: 12px;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(#ebebeb, rgba(0, 0, 0, 0.2));
      border-radius: 0 0 6px 6px;
    }
  
    .pending,
    .fail {
      background-color: #ebebeb;
    }
  
    .pending .status-tip {
      color: #ff700f;
    }
  
    .fail .status-tip {
      color: #f10000;
    }
    /** 自定义插入链接样式 */
    a {
      color: #2496ff;
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
      line-height: 28px;
    }
    a.custom-link {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #1383eb;
      line-height: 22px;
      text-decoration: none;
      position: relative;
      text-indent: 18px;
      display: inline-block;
    }
    a.custom-link::before {
      content: "";
      background: url(https://resource.ghzs.com/css/link.png) no-repeat;
      position: absolute;
      top: 3px;
      left: 0px;
      display: table-cell;
      width: 14px;
      height: 14px;
      background-size: 14px 14px;
      background-position-y: center;
      line-height: 18px;
      transform: translateY(-0.5px);
    }
    .del-icon-box {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 20px;
      height: 20px;
      z-index: 5;
    }
    .insert-image{
      margin: 15px auto;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      border-radius: 4px;
      border:solid 1px rgba(0,0,0,0.1);
    }
      /* 5.0.0*/
    .placeholder-video-container{
      position:relative;
      height:355px;
      width: 100%;
      max-width: 100%;
      margin:15px auto;
      border-radius: 6px;
    }
    .placeholder-video-container .video-wait-tip{
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 12px;
    }
    .placeholder-video-container .video-poster-btn {
      width: 72px;
      height: 28px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 14px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      text-align: center;
    
      position:absolute;
      bottom:8px;
      right:8px;
    }

    .placeholder-video-container .video-play-btn {
      width: 32px;
      height: 32px;
      background: url(https://dev-and-static.ghzs.com/image/article/thumb/2021/05/17/60a2243abd82b7128f3a81f3.png)
        center no-repeat;
      background-size: 100% 100%;
    }
  
    .placeholder-video-container .inner-wrap{
      position:relative;
      width:100%;
      height:355px;
      display:flex;
      align-items:center;
      justify-content:center;
      background:rgba(0, 0, 0, 0.6);
      border-radius:4px;
    }
    
    .placeholder-video-container .no-pass {
      background-color:rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .video-upload-tip {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      line-height: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .progress-bar {
      width: 200px;
      height: 4px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 2px;
      margin-top: 8px;
    }
    .bar {
      width: 120px;
      height: 4px;
      background: #ffffff;
      border-radius: 2px;
    }
  
    .video-upload-fail {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      line-height: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reupload-btn {
      width: 72px;
      height: 28px;
      border-radius: 14px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
    }
    .placeholder-video-container .duration {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: white;
      font-weight: bold;
      background: rgba(10, 10, 10, 0.4);
      padding: 2px 8px;
      font-size: 13px;
      z-index: 11;
      border-radius: 15px;
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding-top: 2px;
      box-sizing: border-box;
    }
      
}