@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































































































.list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 19px;
  padding-bottom: 28px;
  .item {
    cursor: pointer;
    flex: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      margin-right: 16px;
      overflow: hidden;
    }
    .name {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-bottom: 3px;
    }
    .desc {
      width: 192px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      @include ellipsis();
    }
  }
}
.expand-btn {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  span {
    margin-right: 4px;
  }
  img {
    padding-bottom: 2.5px;
  }
}
