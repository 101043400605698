@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";







































.video-recommends {
  border-radius: 4px;
  background: #fff;
  .title {
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    border-bottom: 1px solid #eee;
    line-height: 56px;
    padding-left: 24px;
  }
  .list {
    padding: 8px 0;
    .item {
      display: flex;
      padding: 16px 24px;
    }
    .poster {
      width: 120px;
      height: 68px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-right: 16px;
    }
    .msg-title {
      max-width: 154px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      @include ellipsis();
    }
    .name {
      max-width: 154px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      margin: 4px 0 8px;
      @include ellipsis();
    }
    .other {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
  }
}
