@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































































.search-container {
  width: 1040px;
  display: flex;

  .main {
    width: 680px;
    .tabs {
      display: flex;
      align-items: center;
      padding: 0 24px;
      margin-bottom: 20px;
      .tab {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        cursor: pointer;
      }
      .tab.active {
        color: #2496ff;
        font-weight: 600;
      }
    }
    .result {
      width: 680px;
      height: 898px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f7f7f7;
    }
  }
  .aside {
    width: 340px;
    margin-left: 20px;
  }
}
