@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";













































































































































































































































































































.container {
  width: 1040px;
  display: flex;
  .main {
    width: 680px;
    margin-right: 20px;
    box-sizing: border-box;
    .content-wrapper {
      padding: 28px 24px 24px;
      margin-bottom: 20px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.03);
    }
    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 32px;
      margin-bottom: 16px;
    }
    .labels {
      display: flex;
      margin-bottom: 28px;
    }
    .user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin-bottom: 24px;
      .left {
        display: flex;
        align-items: center;
        .avatar-wrapper {
          width: 32px;
          height: 32px;
          position: relative;
          margin-right: 12px;

          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;

            img {
              width: 100%;
            }
          }
          .auth {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: -1px;
          }
        }
        .name {
          margin-bottom: 4px;
          font-size: 14px;
          font-weight: 500;
          color: $TextColor1st;
        }
        .time {
          font-size: 12px;
          font-weight: 400;
          color: $TextColor3rd;
        }
      }
      .btn {
        width: 56px;
        height: 28px;
        background: #ffffff;
        border-radius: 16px;
        border: 1px solid $DividerColor;
        font-size: 12px;
        font-weight: 400;
        color: $TextColor2nd;
        line-height: 28px;
        text-align: center;
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      margin-bottom: 24px;
    }
    .other {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 48px;
      .bbs {
        display: flex;
        align-items: center;
        max-width: 50vw;
        .bbs-icon {
          display: flex;
          width: 64px;
          height: 64px;
          border-radius: 7px;
          margin-right: 12px;
          cursor: pointer;
          position: relative;
          box-sizing: content-box;
          .box {
            position: relative;
            width: calc(100% - 1px);
            height: calc(100% - 1px);
            position: absolute;
            top: 0.5px;
            left: 0.5px;
            right: 0.5px;
            bottom: 0.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 14px;

            img {
              width: calc(100% - 1px);
              height: calc(100% - 1px);
              border-radius: 7px;
            }
          }
        }
        .game-name {
          height: 28px;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
          margin-bottom: 7px;
        }
        .links {
          display: flex;
          img {
            margin-left: 4px;
            margin-right: 16px;
            padding-top: 2px;
          }
          a {
            font-size: 12px;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.6);
            line-height: 17px;
            display: flex;
          }
        }
      }
      .btns {
        display: flex;
        align-items: center;
        .btn {
          width: 76px;
          height: 32px;
          background: #f5f5f5;
          border-radius: 20px;
          font-size: 12px;
          font-weight: 400;
          color: $TextColor3rd;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          span {
            margin-left: 4px;
          }
        }
        .btn:nth-child(1) {
          margin-right: 16px;
        }
      }
    }
  }
  aside {
    width: 340px;
    min-height: 800px;
  }
}
