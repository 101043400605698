@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.game-comments-detail-page {
  @mixin ellipsiss($row: 2) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @mixin ellipsis() {
    text-overflow: ellipsis; //溢出省略号显示
    white-space: nowrap;
    overflow: hidden;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  $width: 680px;

  .header {
    img {
      cursor: pointer;
      width: 24px;
    }
    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-left: 17px;
    }
  }

  .game-card {
    margin-top: 21px;
    width: $width;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #f7f7f7;
    overflow: hidden;
    padding: 24px 0;
    .gmae-info {
      padding: 0 24px;
      width: $width;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .game-icon {
        width: 64px;
        height: 64px;
        border-radius: 16px;
      }
      .info {
        max-width: 456px;
        padding: 0 0 0 16px;

        .name {
          width: 100%;
          @include ellipsis();
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 33px;
        }

        .tags {
          .tag {
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #cccccc;
            padding: 0 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            margin: 9px 8px 0 0;
          }
        }
      }
      .download-btn {
        flex: 1;
        justify-content: flex-end;

        div {
          cursor: pointer;
          width: 66px;
          height: 28px;
          background: linear-gradient(
            90deg,
            #4bc7ff 0%,
            #2496ff 100%,
            #2496ff 100%
          );
          border-radius: 14px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          text-align: center;
        }
      }
    }

    .comment-main {
      border-top: 1px solid #eee;
      padding: 26px 24px 0 24px;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 24px;
      .userinfo {
        .avatar-wrapper {
          width: 32px;
          height: 32px;
          position: relative;
          margin-right: 8px;
          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            box-sizing: border-box;
            img {
              width: 100%;
            }
          }
          .auth {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
          }
        }
        
        .info {
          flex: 1;
          .name {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 17px;
            display: flex;
            align-items: center;
          }
          .star-and-time {
            .star {
              display: flex;
              img {
                width: 12px;
                height: 12px;
              }
            }
            .time {
              margin-left: 8px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #cccccc;
              line-height: 17px;
            }
          }
        }
      }
      .text {
        position: relative;
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .item-foot {
        margin-top: 16px;
        justify-content: space-between;
        .device {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          line-height: 17px;
          img {
            width: 12px;
            height: 16px;
          }

          .phone,
          .version {
            margin-left: 10px;
          }
        }
        .favo {
          .comm,
          .like {
            cursor: pointer;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            img {
              width: 16px;
              height: 16px;
            }
            span {
              margin-left: 4px;
            }
          }
          .comm {
            margin-right: 32px;
          }
        }
      }
      .reply {
        border-top: 1px solid #eee;
        margin-top: 24px;
        padding: 24px 0 12px 0;

        .user-msg {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          .username {
            color: #666666;
          }
          .msg {
            color: #333333;
          }
        }

        .more-reply {
          cursor: pointer;
          margin-top: 12px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2496ff;
          line-height: 20px;

          img {
            width: 9px;
            height: 20px;
          }
        }
      }
    }
  }

  .all-reply {
    margin: 20px 0 0 0;
    width: $width;
    background-color: #fff;

    &-header {
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      height: 60px;
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;

      .left {
        display: flex;
        align-items: flex-end;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        .name {
          font-size: 20px;
          line-height: 28px;
          margin-right: 4px;
        }
        .num {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .right {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        .gap {
          width: 1px;
          height: 16px;
          background: #e6e6e6;
          margin: 0 8px;
        }
      }
    }

    .reply-list {
      padding: 0 24px;
      .reply-item {
        width: 632px;
        display: flex;
        padding: 24px 0 0 0;
        &:last-child .info{
          border-bottom:none;
        }

        .avatar-wrapper {
          width: 32px;
          height: 32px;
          position: relative;
    
          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            box-sizing: border-box;
            img {
              width: 100%;
            }
          }
          .auth {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
          }
        }

        .info {
          flex: 1;
          margin-left: 8px;
          border-bottom: 1px solid #eee;
          padding: 0 0 24px 0;

          .userinfo {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 17px;
            justify-content: space-between;
            .username {
              color: #666666;
            }
            .time {
              color: #cccccc;
            }
          }

          .text {
            margin-top: 8px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }

          .favo {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;

            span {
              cursor: pointer;
            }

            img {
              cursor: pointer;
              margin: 0 4px 0 32px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
