@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































































































































































































::v-deep .van-tab:first-child {
  margin-left: 28px;
}
::v-deep .van-tabs--line {
  .van-tabs__wrap {
    height: 56px;
  }
  .van-tabs__line {
    width: 20px;
  }
  .van-tab {
    max-width: 80px;
    a {
      color: #999999;
    }
  }
  .van-tab--active a {
    color: #2496ff;
  }
}
.main-page {
  width: 1040px;

  display: flex;

  .left {
    min-height: 1180px;
    width: 680px;
  }

  .right {
    margin-left: 20px;
    width: 340px;
  }

  .game-list {
    margin-top: 1px;
  }
  ::v-deep .no-more .main {
    background: #fff;
  }
}
