@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































































































































































.upload-image-container {
  .entry-box {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    .entry-card {
      width: 100%;
      height: 354px;
      background: #fafafa;
      border-radius: 4px;
      box-sizing: border-box;
      border: 2px dashed #eeeeee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tip-text {
        font-size: 18px;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
        margin-top: 32px;
        margin-bottom: 24px;
      }
      .entry-btn {
        width: 200px;
        height: 40px;
        background: #2496ff;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 16px;
        text-align: center;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .limit-tip {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }
  }

  .cut {
    width: 632px;
    height: 354px;
  }
}
