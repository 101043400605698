@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.content {
  position: relative;
  width: 340px;
  overflow: hidden;

  .flex {
    display: flex;
    align-items: center;
  }

  .hot-content {
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;

    .header {
      box-sizing: border-box;
      width: 340px;
      height: 56px;
      background: rgba(255, 255, 255, 0);

      @extend .flex;
      padding: 0 24px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $TextColor1st;
      }
    }

    .list {
      width: 340px;
      padding: 8px 0;
      .item {
        cursor: pointer;
        display: flex;
        padding: 12px 24px 16px 24px;
        box-sizing: border-box;
        &-tag {
          @extend .flex;
          justify-content: center;
          width: 16px;
          height: 16px;
          border-radius: 2px;
          font-size: 10px;
          font-family: AlibabaSans-BoldItalic, AlibabaSans;
          font-weight: normal;
          color: #ffffff;
          font-style: oblique;
          margin-top: 3px;
        }

        &:hover .item-content-title {
          color: $TextColorBlue;
        }

        &-content {
          flex: 1;
          margin-left: 16px;
          &-title {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            margin-bottom: 16px;
            line-height: 22px;
            color: $TextColor1st;
          }
          &-com {
            justify-content: space-between;
            .mark {
              padding: 4px 6px;
              background: #f0f4f7;
              border-radius: 2px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #7690ad;
            }
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: $TextColor3rd;
            }
          }
        }
      }
    }
  }
}
