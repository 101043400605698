<template>
  <div class="dplayer-wrap ghzs-player" @contextmenu.prevent>
    <div class="dplayer" ref="dplayer"></div>
  </div>
</template>

<script lang="ts" type="ts">
/**
 * http://dplayer.js.org/zh/guide.html#api
 */
import DPlayer from "dplayer"
import merge from "lodash/merge"
import { observer } from "../../plugins/cache"
export default {
  name: "DPlayerVideo",
  data() {
    return {
      isInit: false,
      isWebFullScreen: false,
      maxVolume: 1,
      defaultOptions: {
        container: null,
        preload: "auto",
        autoplay: true,
        theme: "#FADFA3",
        loop: false,
        screenshot: false,
        hotkey: true,
        logo: "",
        volume: 0,
        mutex: true,
        video: {
          url: "",
          pic: "",
          thumbnails: "",
          type: "auto"
        },
        contextmenu: [
          {
            text: "光环助手",
            link: "https://www.ghzs.com"
          }
        ]
      },
      dplayer: null,
      mobileVolume: null
    }
  },
  props: {
    immediate: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    load() {
      if (!this.isInit) {
        this.defaultOptions = merge({}, this.defaultOptions, this.options)
        this.defaultOptions.container = this.$refs.dplayer

        this.dplayer = new DPlayer(this.defaultOptions)

        // 修复源码优先读取localStroage的音量，导致多个player实例相互污染。！
        this.dplayer.user.data.volume = this.dplayer.user.default.volume

        // 自动播放需要关闭声音
        if (this.defaultOptions.autoplay) {
          // this.dplayer.volume(0, true, true)
          // Fix: HTML 不会自动播放的问题
          // console.log(`>>>> 自动播放`)
          setTimeout(() => {
            if (this.dplayer.video.paused) {
              this.dplayer.play()
            }
          }, 30)
        }
        this.dplayer.on("loadstart", () => {
          this.dplayer.template.webFullButton.onclick = () => {
            this.isWebFullScreen = !this.isWebFullScreen

            // console.log(this.isWebFullScreen)
            this.$emit("fullsreen", this.isWebFullScreen)
            // 是否是插件里
            if (
              !this.$isServer &&
              window.Java &&
              typeof window.Java.webFullscreen === "function"
            ) {
              window.Java.webFullscreen(this.isWebFullScreen)
            }
          }

          if (!this.$isServer && window.GhzsUa && !window.GhzsUa.UA.isPC) {
            // 在插件不能用
            setTimeout(() => {
              this.dplayer.template.browserFullButton.style.display = "none"
            })
          }
        })

        this.dplayer.on("ended", () => {
          this.$emit("ended")
        })

        this.dplayer.on("play", () => {
          this.$emit("play")
        })

        this.dplayer.on("volumechange", () => {
          if (this.dplayer.video.muted) {
            this.$emit("volumechange", 0)
          } else {
            this.$emit("volumechange", this.dplayer.user.get("volume"))
          }
        })

        this.isInit = true
        this.$emit("loaded", true)
      }

      return this.dplayer
    },

    changeFullscreenStatus() {
      this.dplayer && this.dplayer.template.webFullButton.click()
    }
  },
  mounted() {
    if (!window.DPlayer) {
      // import("dplayer").then(res => {
      //   window.DPlayer = res.default
      //   observer.subscribe("markFullscreen", this.changeFullscreenStatus)
      //   if (this.immediate) {
      //     this.load()
      //   }
      // })
      window.DPlayer = DPlayer
      observer.subscribe("markFullscreen", this.changeFullscreenStatus)
      if (this.immediate) {
        this.load()
      }
    } else {
      observer.subscribe("markFullscreen", this.changeFullscreenStatus)
      if (this.immediate) {
        this.load()
      }
    }
  },
  beforeDestroy() {
    if (this.mobileVolume) {
      this.mobileVolume.removeEventListener("click", this.onVolume)
    }
    observer.unsubscribe("markFullscreen", this.changeFullscreenStatus)
    this.dplayer && this.dplayer.destroy()
  }
}
</script>

<style lang="scss" scoped>
div.dplayer-wrap {
  font-size: 10px;
}
.ghzs-player {
  width: 100%;
  height: auto;
  background: #d8d8d8;
  & div.dplayer {
    height: 100%;
  }
}
::v-deep .dplayer-volume {
  display: inline-block !important;
}
</style>

<style>
.dplayer-menu-show {
  display: none !important;
}
</style>
