@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































.juhe-game-item {
  width: 420px;
  height: 436px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  background-color: #fff;
  overflow: hidden;
  .juhe-game-info {
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #eee;
    .icon {
      width: 64px;
      height: 64px;
      border-radius: 14px 14px 14px 14px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin-right: 16px;
    }
    .name a {
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      margin-bottom: 8px;
      display: block;
    }
    .tags {
      display: flex;
    }
    .tag a {
      padding: 1px 4px 0;
      margin-right: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      border-radius: 2px;
    }
  }
  .list {
    padding: 24px;
    list-style: initial;
    & > li {
      margin-bottom: 16px;
    }
  }
}
