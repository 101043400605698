@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";















































































































.home {
  width: 1040px;
  display: flex;
  .left {
    width: 680px;

    .sub-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .icon-wrap {
        width: 18px;
        height: 18px;
        display: flex;
        cursor: pointer;
      }
      .sub-nav-left {
        display: flex;
        align-items: center;
      }
      span {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-left: 17px;
      }
    }
    .card {
      padding: 9px 24px 0;
      background-color: #fff;
    }
  }
  .right {
    margin-left: 20px;
    width: 340px;
  }
}
