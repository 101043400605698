@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";













































































































































































.video-info {
  background: #fff;
  padding: 8px 24px 24px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .user {
    padding: 16px 0;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin-right: 16px;
      display: flex;
    }
    .name {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .other {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
    .btn {
      width: 66px;
      height: 28px;
      background: linear-gradient(
        90deg,
        #4bc7ff 0%,
        #2496ff 100%,
        #2496ff 100%
      );
      border-radius: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
    }
    .focused-btn {
      width: 66px;
      height: 28px;
      background: #f5f5f5;
      border-radius: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 28px;
      text-align: center;
    }
  }
  .title {
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    ::v-deep .text {
      margin-top: 8px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .original-tag {
      width: 28px;
      line-height: 14px;
      border-radius: 2px;
      border: 1px solid rgba(36, 150, 255, 0.4);
      font-size: 10px;
      font-weight: 400;
      color: #1383eb;
      text-align: center;
      padding: 1px 4px 0;
    }
    .act {
      display: flex;
      align-items: center;
      margin-left: 8px;
      .bottom-text {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #ff925c;
        line-height: 17px;
        margin-left: 4px;
        padding-top: 1px;
      }
    }
  }

  .game-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    padding: 24px 16px;
    background: #fafafa;
    border-radius: 4px;
    .bbs {
      display: flex;
      align-items: center;
      max-width: 50vw;
      .bbs-icon {
        display: flex;
        width: 64px;
        height: 64px;
        border-radius: 14px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 16px;
        cursor: pointer;
      }
      .info {
        .game-name {
          height: 28px;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
          margin-bottom: 7px;
        }
        .links {
          display: flex;
          img {
            margin-left: 4px;
            margin-right: 16px;
            padding-top: 2px;
          }
          a {
            font-size: 12px;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.6);
            line-height: 17px;
            display: flex;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .btn {
        width: 76px;
        height: 32px;
        background: #f5f5f5;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 400;
        color: $TextColor3rd;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
          margin-left: 4px;
        }
      }
      .btn:nth-child(1) {
        margin-right: 16px;
      }
    }
  }
}
