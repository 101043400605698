@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


































.breadcrumb-comp {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  .breads {
    display: flex;
    align-items: center;
    .bread-item {
      display: flex;
      align-items: center;
    }
    .arrow {
      margin: 0 9px;
      font-weight: bold;
      width: 9px;
      height: 20px;
      overflow: hidden;
    }
    .name {
      color: #999;
      max-width: 300px;
      @include ellipsis();
      &:hover:not(:last-child) {
        color: #2496ff;
      }
      &:last-child {
        color: #333333;
      }
    }
  }
}
