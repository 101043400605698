@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




































































.game-list-container {
  width: 1040px;
  display: flex;
  .main {
    width: 680px;
    .content {
      box-sizing: border-box;
      min-height: 876px;
      padding: 24px;
      background: #fff;

      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      ::v-deep img {
        width: 100%;
      }
    }
  }

  .aside {
    width: 340px;
    margin-left: 20px;
  }
}
