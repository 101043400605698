@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































.novel-column {
  height: 247px;
  padding: 24px;
  box-sizing: border-box;
  background: #fff;
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      max-width: 500px;
      @include ellipsis();
    }
    .btn {
      display: flex;
      align-items: flex-end;
    }
    .btn-text {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #2496ff;
      line-height: 22px;
    }
    .btn-text,
    .btn-more {
      display: block;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 104px;
        height: 104px;
        border-radius: 26px;
        margin-bottom: 16px;
        overflow: hidden;
        font-size: 12px;
      }
      .game-name {
        max-width: 96px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        @include ellipsis();
      }
    }
  }
}
