@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";







































































































































::v-deep .van-popup {
  width: 558px;
  height: 414px;
  border-radius: 4px;
  // background: linear-gradient(180deg, #ffffff 0%, #e7f3ff 100%);
  background-color: #fff;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}

.wrapper {
  position: relative;
  padding: 28px 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .flex {
    display: flex;
    align-items: center;
  }

  .primary {
    color: #2496ff;
  }

  .cursor {
    cursor: pointer;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    height: 28px;
  }
  .desc {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    display: flex;
    align-items: center;
    span {
      max-width: 300px;
      display: inline-block;
      @include ellipsis();
    }
  }
  .content {
    display: flex;
    align-items: center;
    padding: 0 0 0 48px;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 46px 0 48px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 26px;

    .btn {
      width: 220px;
      height: 46px;
      line-height: 46px;
      background: #2496ff;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      cursor: pointer;
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
