@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




































.game-desc {
  padding: 24px 24px 16px 24px;
  .game-desc-header {
    justify-content: space-between;
    .left {
      display: flex;
      align-items: flex-end;
      .tag {
        width: 16px;
        height: 16px;
      }
      .desc {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin: 0 4px 0 3px;
      }
    }
  }
}
