@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































.game-big-event {
  box-sizing: border-box;
  padding: 16px 0;
  margin: 0 24px;

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .time-box {
    margin-top: 16px;
    justify-content: space-between;
    .left {
      height: 32px;
      background: #f4f5f6;
      border-radius: 16px;
      opacity: 0.6;
      padding: 0 10px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 32px;

      .time {
        padding: 0 2px;
      }
    }
    .right {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2496ff;
      line-height: 20px;
      img {
        width: 9px;
        height: 20px;
      }
    }
  }

  .plugin-desc {
    border-bottom: 1px solid #eee;
    padding: 16px 0;
    .plugin-desc-header {
      justify-content: space-between;
      margin-bottom: 16px;
      .left {
        display: flex;
        align-items: flex-end;
        .tag {
          width: 16px;
          height: 16px;
        }
        .desc {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          margin: 0 4px 0 3px;
        }
        .use {
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2496ff;
          line-height: 20px;
          text-decoration: underline;
        }
      }
      .right {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2496ff;
        img {
          width: 9px;
          height: 20px;
        }
      }
    }

    .plugin-list {
      margin-top: 12px;
      .plugin-item {
        margin-right: 24px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2496ff;
          line-height: 20px;
        }
      }
    }
  }

  .game-desc {
    margin-top: 24px;
    .game-desc-header {
      justify-content: space-between;
      .left {
        display: flex;
        align-items: flex-end;
        .tag {
          width: 16px;
          height: 16px;
        }
        .desc {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          margin: 0 4px 0 3px;
        }
      }
    }
  }
}
