@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


























































.juhe-game-list {
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  & > div:nth-child(3n + 3) {
    margin-right: 0;
  }
}
