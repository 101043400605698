@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


















































































































.game-page {
  width: 1040px;
  display: flex;

  .main {
    width: 680px;
  }

  .aside {
    width: 340px;
    margin-left: 20px;
  }
}
