@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


















































































































































































































































































































































































































































































































































































































































































































.create-container {
  width: 1040px;
  display: flex;
  margin: 0 auto;
  .main-wrap {
    width: 680px;
    margin-right: 20px;
    padding-bottom: 84px;
    position: relative;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .left {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
        }
      }
      .game-icon {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 24px;
        margin-right: 12px;
      }
      .game-name {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .btn {
        height: 32px;
        background: #ffffff;
        border-radius: 20px;
        border: 1px solid #eeeeee;
        padding: 0 16px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          color: #1383eb;
        }
      }
    }
    .entry-box {
      width: 100%;
      height: 402px;
      box-sizing: border-box;
      padding: 24px;
      background: #fff;
      .entry-card {
        width: 100%;
        height: 354px;
        background: #fafafa;
        border-radius: 4px;
        box-sizing: border-box;
        border: 2px dashed #eeeeee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .tip-text {
          font-size: 18px;
          font-weight: 500;
          color: #999999;
          line-height: 18px;
          margin-top: 32px;
          margin-bottom: 24px;
        }
        .entry-btn {
          width: 200px;
          height: 40px;
          background: #2496ff;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 40px;
          margin-bottom: 16px;
          text-align: center;
          cursor: pointer;
        }
        .limit-tip {
          height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
        }
      }
    }
    .content-box {
      padding: 24px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.03);
      border-radius: 4px;
      margin-bottom: 20px;

      input,
      textarea {
        display: block;
        background: none;
        outline: none;
        border: none;
        margin: 0px;
        width: 100%;
      }

      .title {
        margin-top: 32px;
        margin-bottom: 20px;
        padding-bottom: 24px;
        border-bottom: 1px solid #eeeeee;
        input {
          color: #333333;
          height: 16px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
      }
      .content {
        min-height: 72px;
        ::v-deep .van-cell {
          padding: 0;
        }
        textarea {
          font-size: 16px;
          font-weight: 400;
          color: #4a4a4a;
          line-height: 24px;
        }
      }
    }
    .selection-box {
      min-height: 204px;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.03);
      .label {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .tip {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
      .select-tag {
        padding: 24px 24px 8px;
        border-bottom: 1px solid #eee;

        .tags {
          display: flex;
          flex-wrap: wrap;
          .tag {
            height: 28px;
            background: #fafafa;
            border-radius: 16px;
            border: 1px solid #eeeeee;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 28px;
            margin-right: 12px;
            padding: 0 16px;
            margin-bottom: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:not(.tag-active):hover {
              background: #ffffff;
              border: 1px solid #fa8850;
              color: #fa8850;
            }
          }
          .tag-active {
            color: #ffffff;
            border: 1px solid #f57445;
            background: #fa8850;
            padding-right: 6px;
          }
        }
      }
      .original-box {
        padding: 24px;
        .radio-box {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
        }
        input {
          margin-top: 16px;
          width: 632px;
          height: 40px;
          background: #fafafa;
          border-radius: 2px;
          border: 1px solid #eeeeee;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 40px;
          padding: 0 16px;
          box-sizing: border-box;
        }
      }
      .tip-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .label {
          margin-left: 8px;
          margin-right: 12px;
        }
      }
    }
  }
  .left {
    margin-left: 24px;
    display: flex;
    align-items: center;
  }
  .selected-section-btn {
    cursor: pointer;
    height: 28px;
    background: #fafafa;
    border-radius: 16px;
    border: 1px solid #eeeeee;
    padding: 0 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .selected-section-name {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
    .arrow {
      margin-left: 8px;
    }
  }
  .select-section-btn {
    cursor: pointer;
    width: auto;
    padding: 0 16px;
    height: 28px;
    background: #f7fbfe;
    border-radius: 16px;
    border: 1px solid #eaf5ff;
    font-size: 12px;
    font-weight: 400;
    color: #1383eb;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .arrow {
      margin-left: 4px;
    }
  }

  aside {
    width: 340px;
    min-height: 800px;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #ccc;
  }
}
