@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.detail-game-gift {
  padding: 24px 24px 16px 24px;
  .flex {
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .content {
    .list {
      .item {
        width: 100%;
        padding: 16px 0 12px 0;
        .left {
          width: 100%;
          .gift-name {
            img {
              width: 16px;
              height: 16px;
            }
            span {
              margin-left: 8px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
            }
          }
          .progress {
            justify-content: space-between;
            .progress-bg {
              width: 502px;
              height: 4px;
              background: #f0f4f7;
              border-radius: 2px;
              .grade {
                height: 4px;
                background: #2496ff;
                border-radius: 2px;
              }
            }
            .progress-btn {
              cursor: pointer;
              width: 66px;
              height: 28px;
              background: linear-gradient(
                90deg,
                #4bc7ff 0%,
                #2496ff 100%,
                #2496ff 100%
              );
              border-radius: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fff;

              &[status="tao"],
              &[status="ling"] {
                background: linear-gradient(
                  90deg,
                  #4bc7ff 0%,
                  #2496ff 100%,
                  #2496ff 100%
                );
                color: #fff;
              }
              &[status="coming"],
              &[status="check"],
              &[status="used_up"] {
                border: 1px solid #2496ff;
                color: #2496ff;
                background-color: #fff;
              }
              &[status="finish"],
              &[status="unshelve"] {
                color: #dddddd;
                border: 1px solid #dddddd;
                background-color: #fff;
              }
            }
          }
          .gift-desc {
            width: 502px;
            justify-content: space-between;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 17px;
            .desc {
              color: #999999;
            }
            .num {
              color: #2496ff;
            }
          }
        }
      }
    }

    .view-more {
      cursor: pointer;
      margin-top: 12px;
      width: 632px;
      height: 46px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px solid #eeeeee;

      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2496ff;
      text-align: center;
      line-height: 48px;
    }
  }
}
