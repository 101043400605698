@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































































































.video-comments {
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 4px;

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    margin-bottom: 24px;
    .left {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      vertical-align: middle;
      span {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
    .sort-btns {
      margin-left: 32px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    .divider {
      width: 1px;
      height: 16px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 0 8px;
    }
    .active {
      color: #2496ff;
    }
  }
  .comment-card {
    padding: 24px;
    padding-top: 0;
  }
  .replys {
    margin-left: 40px;
  }
  .more-reply-btn {
    width: 852px;
    height: 46px;
    background: #ffffff;
    border-radius: 23px;
    border: 1px solid #eeeeee;
    font-size: 16px;
    font-weight: 500;
    color: #2496ff;
    line-height: 46px;
    text-align: center;
    margin-left: 64px;
    cursor: pointer;
  }

  .no-more {
    display: flex;
    margin: 20px;
    margin-bottom: 0;
  }
  .load-more {
    display: flex;
    margin: 20px 20px 0;
  }
}
