@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
































































































































































::v-deep .van-tabs {
  width: 140px;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 55px;
}
::v-deep .van-tab {
  margin-left: 28px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
::v-deep .van-tabs__line {
  width: 20px;
}
::v-deep .van-tab--active {
  color: #2496ff;
}
.tag-container {
  width: 1040px;
  display: flex;

  .main {
    width: 680px;
    .flex {
      display: flex;
      align-items: center;
    }

    .header {
      background-color: transparent;
      img {
        cursor: pointer;
        width: 24px;
      }
      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-left: 17px;
      }
    }

    .tabs-box {
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #f7f7f7;
      margin-top: 22px;
    }

    .result {
      width: 680px;
      height: 898px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f7f7f7;
    }

    .search-list {
      background: #fff;
      padding: 16px 24px 26px 24px;
    }
    .empty-area {
      width: 680px;
      height: 680px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .aside {
    width: 340px;
    margin-left: 20px;
  }
}
