@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
































































































.comment-card {
  padding: 24px;
  display: flex;
  .left {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
  }
  .content-box {
    flex: 1;
    border-bottom: 1px solid #eee;
    padding-bottom: 24px;
    .msg {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .msg-l {
      display: flex;
      align-items: center;
      .name {
        font-size: 12px;
        font-weight: 500;
        color: #666666;
        margin-right: 4px;
      }
    }
    .time {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #cccccc;
      line-height: 17px;
    }
    .is_top {
      width: 48px;
      height: 26px;
      background: rgba(255, 65, 71, 0.1);
      border-radius: 2px;
      font-weight: 400;
      color: #ff4147;
      line-height: 26px;
      text-align: center;
      font-size: 16px;
      transform: scale(0.5, 0.5);
      transform-origin: left;
      margin-top: -5px;
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-bottom: 8px;
    }
    .imgs {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
      .img-item {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
        margin-bottom: 8px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 9px;
        cursor: pointer;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      .reply-btn {
        margin-right: 32px;
        cursor: pointer;
      }
      .like {
        display: flex;
        cursor: pointer;
      }
      .vote {
        margin-left: 4px;
      }
    }
  }
}
