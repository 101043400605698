@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";















































































::v-deep .swiper-pagination-bullets {
  right: 24px !important;
  bottom: 15px !important;
  left: auto !important;
  width: auto !important;
}
::v-deep .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #ffffff;
  opacity: 0.6;
}
::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
}

.slide-item {
  width: 680px;
  height: 382px;
  border-radius: 4px;
  overflow: hidden;
  .swiper-title {
    max-width: 384px;
    height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
    position: absolute;
    left: 24px;
    bottom: 16px;
    z-index: 10;
    @include ellipsis();
  }
}
.mask {
  width: 100%;
  height: 72px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("~@/assets/images/mask.png");
  background-size: 100% 100%;
}
