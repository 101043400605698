@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.detail-images {
  position: relative;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  padding: 24px 24px 16px 24px;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .content {
    padding: 16px 0 0 0;
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .item {
      width: max-content;
      height: 192px;
      cursor: pointer;
      .img {
        display: block;
        width: auto;
        height: 192px;
        border-radius: 4px;
      }
    }
  }
  .aside-arrow-left {
    position: absolute;
    left: 24px;
    top: 68px;
    width: 28px;
    height: 192px;
    z-index: 10;

    img {
      width: 28px;
      height: 192px;
    }
  }

  .aside-arrow-right {
    position: absolute;
    right: 24px;
    top: 68px;
    width: 28px;
    height: 192px;
    z-index: 10;

    img {
      width: 28px;
      height: 192px;
    }
  }
}
