@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































































































































































































































































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.bread {
  white-space: nowrap;
}

.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  max-width: 1350px;
  margin: 0 auto;
  .left {
    display: flex;
    align-items: center;
    .img {
      width: 68px;
      height: 16px;
    }
    .down-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 12px;
      cursor: pointer;
      img.app-phone {
        width: 11px;
        height: 14px;
      }
      span {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        color: #2496ff;
        margin-top: 2px;
        margin-left: 4px;
      }
    }
  }

  .right {
    width: 360px;
    height: 32px;
    background: #f5f5f5;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 64px;
    box-sizing: border-box;
    .img {
      width: 20px;
      height: 20px;
    }

    .clear {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    input {
      margin-left: 8px;
      margin-right: 8px;
      height: 100%;
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      color: $TextColor1st;
      border: none;
      background-color: transparent;
      padding: 0;
      caret-color: #3d96ff;
    }

    input::-webkit-input-placeholder {
      color: $TextColor;
    }

    .tips {
      width: 360px;
      max-height: 536px;
      background: #ffffff;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding: 20px;
      padding-bottom: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 5;
      box-sizing: border-box;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
      position: relative;
    }
    .list-item {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-bottom: 20px;
      cursor: pointer;
      @include ellipsis();
    }
    .clear-btn {
      font-size: 16px;
      font-weight: 400;
      color: #2496ff;
      line-height: 22px;
      position: absolute;
      right: 0;
      top: 3px;
      cursor: pointer;
    }
    .search-btn {
      cursor: pointer;
      width: 60px;
      height: 32px;
      background: #3d96ff;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      text-align: center;
      position: absolute;
      right: 0;
      z-index: 50;
    }
  }
  .active-right {
    border: 1px solid #3d96ff;
    background-color: #fff;
  }
  .user-wrapper {
    position: relative;
    .user {
      width: 32px;
      height: 32px;
      cursor: pointer;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
    }
    .user-options {
      position: absolute;
      right: 0;
      bottom: -125px;
      z-index: 10000;
      width: 240px;
      padding: 4px 0;
      background: #ffffff;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      .option-item {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 46px;
        padding-left: 24px;
        margin: 4px 0;
        height: 46px;
        cursor: pointer;
        &:hover {
          background: rgba(36, 150, 255, 0.1);
        }
      }
    }
  }
}
