@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";








































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.bbs-list {
  position: relative;
  box-sizing: border-box;

  min-width: 680px;
  width: 100%;
  border-radius: 6px;
  ::v-deep .result-card {
    background: #ffffff;
  }
}
