@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





















































.footer-card {
  width: 340px;
  height: 118px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  padding: 24px;
  box-sizing: border-box;

  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  .can-click {
    cursor: pointer;
  }
}
