@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




































































::v-deep .van-popup {
  width: 402px;
  height: 293px;
  border-radius: 4px;
  // background: linear-gradient(180deg, #ffffff 0%, #e7f3ff 100%);
  background-color: #fff;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}

.wrapper {
  position: relative;
  box-sizing: border-box;

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 24px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    border-bottom: 1px solid #eee;
    padding: 16px 24px;
  }
  .content {
    padding: 20px 24px;
    .tip {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .tip-title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin: 12px 0 8px;
    }
    .tip-item {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      padding-left: 14px;
      position: relative;
      margin-bottom: 8px;
      .red {
        color: #ff494f;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 20px;
        background-image: url(~@/assets/images/icon_delimiter.png);
        background-size: contain;
      }
    }
    .btn {
      width: 108px;
      height: 40px;
      background: #2496ff;
      border-radius: 20px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      margin: 16px auto 0;
      cursor: pointer;
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
