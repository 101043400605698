@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.content {
  position: relative;
  width: 100%;
  border-radius: 0 0 4px 4px;

  .flex {
    display: flex;
    align-items: center;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .hot-bbs {
    background: #ffffff;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;

    .header {
      box-sizing: border-box;
      width: 340px;
      height: 56px;
      background: rgba(255, 255, 255, 0);
      @extend .flex;
      padding: 0 24px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $TextColor1st;
      }
    }

    .list {
      padding: 8px 0;
      .item {
        cursor: pointer;
        width: calc(50% - 16px);
        display: flex;
        align-items: center;
        padding: 16px 24px;

        .game-icon {
          width: 48px;
          height: 48px;
          border-radius: 12px;
          border: 1px solid $BorderColor;
          margin-right: 16px;
        }

        .info {
          flex: 1;
          .name {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $TextColor1st;
            min-width: 100px;
            @extend .ellipsis;
          }
          .desc {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 400;
            min-width: 100px;
            color: $TextColor3rd;
            display: flex;
            align-items: center;
          }
        }

        &:hover .name {
          color: $TextColorBlue;
        }
      }
    }
  }
}
