@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































































.article-list-card {
  width: 1300px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  .title {
    height: 56px;
    line-height: 56px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding: 0 24px;
    border-bottom: 1px solid #eee;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }
  .col {
    padding: 24px 24px 18px;
    list-style: initial;
    width: 420px;
    box-sizing: border-box;
    & > li {
      margin-bottom: 16px;
    }
  }
}
