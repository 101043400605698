@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";












































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.search-list {
  background: #fff;
  padding: 24px;
  .search-item {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info {
      display: flex;
      .game-icon {
        width: 72px;
        height: 72px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 12px;
      }
      .game-title {
        max-width: 320px;
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-top: 4px;
        margin-bottom: 8px;
        @include ellipsis();
      }
      .desc {
        max-width: 380px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;

        @include ellipsis();
      }
    }
    .btn {
      width: 65px;
      height: 28px;
      background: linear-gradient(
        90deg,
        #4bc7ff 0%,
        #2496ff 100%,
        #2496ff 100%
      );
      border-radius: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
    }
  }
  .search-item:last-child {
    margin-bottom: 0;
  }
}
.empty-area {
  width: 680px;
  height: 680px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
