@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.detail-info-strategy {
  padding: 24px 24px 16px 24px;
  position: relative;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  @mixin ellipsiss($row: 2) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .flex {
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .content {
    padding: 16px 0 0 0;
    .list {
      position: relative;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      .item {
        cursor: pointer;
        box-sizing: border-box;
        width: 244px !important;
        height: 223px;
        background: #fafafa;
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .img {
          border-radius: 4px 4px 0 0;
          width: 244px;
          height: 122px;
        }

        .name {
          width: 212px;
          padding: 16px 16px 0 16px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
          @include ellipsiss();
        }
        .desc {
          flex: 1;
          width: 212px;
          padding: 8px 16px 0 16px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          @include ellipsiss(5);
        }
        .time {
          width: 212px;
          padding: 8px 16px 16px 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          line-height: 17px;
        }
      }
    }

    .view-more {
      cursor: pointer;
      margin-top: 24px;
      width: 632px;
      height: 46px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px solid #eeeeee;

      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2496ff;
      text-align: center;
      line-height: 48px;
    }
  }

  .aside-arrow-left {
    cursor: pointer;
    position: absolute;
    left: 24px;
    top: 68px;
    width: 28px;
    height: 223px;
    z-index: 10;

    img {
      width: 28px;
      height: 223px;
    }
  }

  .aside-arrow-right {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 68px;
    width: 28px;
    height: 223px;
    z-index: 10;

    img {
      width: 28px;
      height: 223px;
    }
  }
}
