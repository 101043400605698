@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";











































































































.menu-contatiner {
  flex: 0 0 240px;
  box-sizing: border-box;
  position: sticky;
  top: 76px;
  font-size: 0;
  .menu-item {
    width: 240px;
    height: 48px;
    box-sizing: border-box;
    padding-left: 26px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 28px;
    cursor: pointer;
  }
  .menu-item.active {
    color: #1383eb;
    background: #fff;
  }
  .menu-item.active:hover {
    background: #fff;
  }
  .menu-item:hover {
    background: #f5f5f5;
  }
  .menu-icon {
    margin-right: 13px;
  }
}
