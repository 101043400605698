@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























































































































.home {
  width: 1040px;

  display: flex;

  .left {
    min-height: 1180px;
    width: 680px;
  }

  .right {
    margin-left: 20px;
    width: 340px;
  }
}
