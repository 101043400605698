@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



































































































































































::v-deep .van-popup {
  width: 680px;
  height: 536px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 10001 !important;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10001 !important;
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-tab:first-child {
  margin-right: 24px;
}
::v-deep .van-tabs--line {
  .van-tabs__wrap {
    height: 56px;
  }
  .van-tabs__line {
    width: 20px;
  }
}

.selects {
  padding: 24px 24px 8px;
  display: flex;
  flex-wrap: wrap;
  .select-item {
    width: 200px;
    height: 112px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    &:nth-child(3),
    &:nth-child(3n) {
      margin-right: 0;
    }
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .select-item.active {
    border-radius: 0px;
    position: relative;
    &::after {
      content: "";
      width: 194px;
      height: 106px;
      border: 2px solid #2496ff;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}
.upload {
  padding: 24px;
}

.title-wrapper {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: $TextColor1st;
  }
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    width: 104px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .reupload-btn {
    border: 1px solid #eeeeee;
    color: #666666;
    margin-right: 16px;
    &:hover {
      background-color: #eeeeee;
    }
  }
  .submit-btn {
    background: #2496ff;
    color: #ffffff;
    margin-right: 24px;
    &:hover {
      opacity: 0.8;
    }
  }
}

element::-webkit-scrollbar {
  display: none;
}
