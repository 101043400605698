@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.detail-player-comment {
  padding: 24px 24px 16px 24px;

  .flex {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .ellipsiss {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .content {
    .item {
      cursor: pointer;
      display: block;
      position: relative;
      padding: 24px 0;
      .userinfo {
        .avatar-wrapper {
          width: 32px;
          height: 32px;
          position: relative;
          margin-right: 8px;
          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            box-sizing: border-box;
            img {
              width: 100%;
            }
          }
          .auth {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
          }
        }
        .info {
          .name {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 17px;
            display: flex;
            align-items: center;
          }
          .star-and-time {
            .star {
              display: flex;
              img {
                width: 12px;
                height: 12px;
              }
            }
            .time {
              margin-left: 8px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #cccccc;
              line-height: 17px;
            }
          }
        }
      }
    }

    .view-more {
      display: block;
      cursor: pointer;
      width: 632px;
      height: 46px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px solid #eeeeee;

      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2496ff;
      text-align: center;
      line-height: 48px;
    }
  }
}
