@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";










































.home-tag-card {
  padding-bottom: 8px;
  max-height: 208px;
  background: #fff;
  .title {
    line-height: 56px;
    height: 56px;
    border-bottom: 1px solid #eee;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 8px;
  }
  .tag-list {
    display: flex;
    padding: 16px 24px 0px;
    flex-wrap: wrap;
    max-height: 136px;
    box-sizing: border-box;
    overflow: hidden;
    .tag {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #7690ad;
      line-height: 17px;
      padding: 4px 8px 3px;
      background: #f0f4f7;
      border-radius: 2px;
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
}
