@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
















































.layout-container {
  min-height: 100vh;
  box-sizing: border-box;
  transition: 0.2s;
  background-color: $BgColor;

  header {
    height: 56px;
    background: #ffffff;
  }

  main {
    display: flex;
    justify-content: space-between;
    max-width: 1350px;
    margin: 0 auto;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
    .menu {
      height: 100%;
    }
  }
}
.error-page {
  box-sizing: border-box;
  background: $BgColor;
  width: 100%;
  display: flex;
  width: 1040px;

  .main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;

    img {
      width: 160px;
      height: 160px;
    }

    .name {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $TextColor3rd;
      line-height: 16px;
      padding: 0 16px;
    }

    .desc {
      margin-top: 8px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $TextColor;
      line-height: 12px;
      padding: 0 16px;
      font-size: 12px;
    }
  }

  .aside {
    width: 340px;
    margin-left: 20px;
  }
}
