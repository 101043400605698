@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

















































































::v-deep .van-popup {
  width: 400px;
  min-height: 190px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.title-wrapper {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: $TextColor1st;
  }
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.draftDialog {
  .content {
    padding: 20px 24px 24px;
    .text {
      font-size: 14px;
      font-weight: 400;
      color: #4a4a4a;
      line-height: 22px;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        width: 80px;
        height: 40px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        margin-top: 28px;
        cursor: pointer;
      }
      .btn.cancel {
        border: 1px solid #eeeeee;
        color: #666666;
        margin-right: 16px;
      }
      .btn.submit {
        background: #2496ff;
        color: #ffffff;
      }
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
