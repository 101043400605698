.detail-uppdate-open-service {
  padding: 24px 24px 16px 24px;
  .flex {
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }

  .content {
    .info {
      padding: 16px 0 0 0;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;

      .left {
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }

    .view-more {
      cursor: pointer;
      margin-top: 24px;
      width: 632px;
      height: 46px;
      background: #ffffff;
      border-radius: 23px;
      border: 1px solid #eeeeee;

      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2496ff;
      text-align: center;
      line-height: 48px;
    }
  }
}
