@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


































































































::v-deep .van-popup {
  width: 520px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 10001 !important;
  overflow: hidden;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10001 !important;
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-tab:first-child {
  margin-right: 24px;
}
::v-deep .van-tabs--line {
  .van-tabs__wrap {
    height: 56px;
  }
  .van-tabs__line {
    width: 20px;
  }
}

.title-wrapper {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.tags {
  padding: 24px 24px 8px;
  display: flex;
  flex-wrap: wrap;
  .tag {
    background: #f4f5f6;
    border-radius: 20px;
    padding: 8px 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
    line-height: 20px;
    cursor: pointer;
    img {
      margin-left: 5px;
      padding-top: 1px;
    }
  }
}

element::-webkit-scrollbar {
  display: none;
}
