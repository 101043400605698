@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























.comment-input-component {
  height: 44px;
  background: #f5f5f5;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    margin-right: 9px;
  }
  span {
    padding-top: 2px;
    line-height: 16px;
  }
}
