@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
.detail-videos {
  padding: 24px 24px 16px 24px;
  position: relative;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .flex {
    display: flex;
    align-items: center;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: flex-end;
      .name {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
      }
      .num {
        margin-left: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }

    .right {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2496ff;
      line-height: 20px;

      img {
        width: 9px;
        height: 20px;
      }
    }
  }

  .content {
    padding: 16px 0 0 0;
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .item {
      width: 342px;
      position: relative;
      .banner {
        width: 342px;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px;
      }
      .img {
        display: block;
        width: 342px;
        height: 192px;
        border-radius: 4px;
      }

      .img-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 342px;
        height: 192px;
        display: flex;
        align-items: flex-end;
        padding: 0 12px 8px 12px;
        box-sizing: border-box;
        z-index: 10;

        .player {
          cursor: pointer;
          position: absolute;
          top: calc(50% - 26px);
          left: calc(50% - 26px);
          z-index: 12;
        }

        .img-box-desc {
          flex: 1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
        .img-box-favo {
          display: flex;
          align-items: center;
          img {
            margin-right: 4px;
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }

      .itme-desc {
        width: 342px;
        margin-top: 12px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        text-overflow: ellipsis; //溢出省略号显示
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .aside-arrow-left {
    cursor: pointer;
    position: absolute;
    left: 24px;
    top: 68px;
    width: 28px;
    height: 192px;
    z-index: 10;

    img {
      width: 28px;
      height: 192px;
    }
  }

  .aside-arrow-right {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 68px;
    width: 28px;
    height: 192px;
    z-index: 10;

    img {
      width: 28px;
      height: 192px;
    }
  }
}
