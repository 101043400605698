@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";








































































































































































































































































































.login-container {
  width: 1040px;
  height: 856px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  margin: 0 auto;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    padding: 17px 24px;
    border-bottom: 1px solid #eee;
  }
  .login-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 68px;
  }
  .logo-name {
    width: 73px;
    height: 15px;
    margin-top: 16px;
    margin-bottom: 49px;
  }
  .form-input {
    width: 328px;
    height: 48px;
    background: #ffffff;
    border-radius: 24px;
    border: 1px solid #ededed;
    padding: 14px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    input {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      border: none;
    }
    input::-webkit-input-placeholder {
      color: #cccccc;
    }
    .area-num {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding-right: 8px;
      border-right: 1px solid #d8d8d8;
      margin-right: 8px;
    }
    .validate-btn {
      width: 70px;
      font-size: 14px;
      font-weight: 400;
      color: #2496ff;
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
      margin-left: 20px;
    }
    .count {
      width: 24px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
      line-height: 20px;
    }
  }
  .tip {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    display: flex;
    a {
      color: #1383eb;
    }
  }
  .login-btn {
    width: 328px;
    height: 48px;
    background: #2496ff;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 68px;
    cursor: pointer;
  }
  .other {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    .line {
      width: 93px;
      height: 1px;
      background: #eeeeee;
    }
    .other-title {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin: 0 8px;
    }
  }
  .login-ways {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    width: 328px;
    box-sizing: border-box;
    .login-way {
      cursor: pointer;
    }
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      text-align: center;
      margin-top: 4px;
    }
  }
}
