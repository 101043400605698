@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































































::v-deep .van-popup {
  width: 400px;
  height: 280px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.title-wrapper {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: $TextColor1st;
  }
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.shares {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 24px;
  .qr-wrapper {
    width: 96px;
    height: 96px;
    background: #f5f6f7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tip {
    font-size: 14px;
    font-weight: 500;
    color: $TextColor2nd;
    margin: 18px 0 24px;
  }
  .link {
    width: 100%;
    height: 32px;
    line-height: 32px;
    background: #fafafa;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 12px;
    font-weight: 400;
    color: $TextColor2nd;
    position: relative;
    padding-left: 12px;
    overflow: hidden;
    .copy {
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #2496ff;
      border-radius: 0px 2px 2px 0px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
