@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";












































































.game-zone {
  cursor: pointer;
  position: relative;
  width: 340px;
  height: 112px;
  overflow: hidden;
  border-radius: 4px;

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;

    &-info {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px;
      z-index: 2;

      &-left {
        &-info {
          display: flex;
          align-items: center;
          margin-bottom: 3px;

          img {
            width: 16px;
            height: 16px;
          }
          span {
            margin-left: 8px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }

        &-zone {
          position: relative;
          width: 119px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      &-arrow {
        margin-top: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }

  &-bg {
    width: 100%;
    height: 100%;
  }
}
