@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


























































.ellipsis-text {
  position: relative;
  margin-top: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  overflow: hidden;
}
.more-text {
  cursor: pointer;
  width: 70px;
  position: absolute;
  bottom: 0px;
  right: 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2496ff;
  text-align: right;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );
}
