@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




























































































































































































































































.bbs {
  width: 1040px;
  display: flex;
  .left {
    width: 680px;

    .empty {
      width: 100%;
      height: 680px;
      background-color: #fff;
    }
  }

  .right {
    margin-left: 20px;
    width: 340px;
  }
}

::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.home-content {
  position: relative;
  box-sizing: border-box;
  min-width: 680px;
  width: 100%;

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  $height: 112px;

  .header-box {
    overflow: hidden;
    border-radius: 4px 4px 0 0;
    position: relative;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $height;
    }

    .header-box-mask {
      height: $height;
      background: rgba(0, 0, 0, 0.4);
      filter: blur(0px);
    }

    &-game {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      width: 100%;
      height: $height;
      padding: 24px;
      box-sizing: border-box;

      &[hasbg="yes"] {
        background-color: transparent;
      }

      &[hasbg="no"] {
        background-color: #fff;
      }

      &-icon {
        position: relative;
        width: 66px;
        height: 66px;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 16px;
        box-sizing: content-box;

        .box {
          position: relative;
          width: calc(100% - 1px);
          height: calc(100% - 1px);
          position: absolute;
          top: 0.5px;
          left: 0.5px;
          right: 0.5px;
          bottom: 0.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 24px;

          .img {
            width: 64px;
            height: 64px;
            border-radius: 12px;
          }
        }
      }

      &-info {
        flex: 1;
        margin-left: 16px;
        &-name {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;

          &[hasbg="yes"] {
            color: #ffffff;
          }

          &[hasbg="no"] {
            color: #333333;
          }
        }
        &-desc {
          width: 400px;
          margin-top: 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;

          &[hasbg="yes"] {
            color: rgba(255, 255, 255, 0.6);
          }

          &[hasbg="no"] {
            color: $TextColor3rd;
          }
        }
      }

      &-btn {
        cursor: pointer;
        width: 56px;
        height: 28px;
        border-radius: 28px;
        @extend .flex;
        justify-content: center;

        &[hasbg="yes"] {
          border: 1px solid rgba(255, 255, 255, 0.3);
          background-color: rgba(0, 0, 0, 0.3);
        }

        &[hasbg="no"] {
          border: 1px solid #eeeeee;
          background-color: #fff;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;

          &[hasbg="yes"] {
            color: #ffffff;
          }

          &[hasbg="no"] {
            color: #666666;
          }
        }
      }
    }

    .tops {
      padding: 8px 0;
      position: relative;
      background: #fff;
      .top-item {
        cursor: pointer;
        height: 36px;
        padding: 0 44px 0 24px;
        display: flex;
        align-items: center;

        &:hover h2 {
          color: $TextColorBlue;
        }

        h2 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          color: $TextColor1st;
          margin-left: 8px;
          @extend .ellipsis;
        }
      }
      .t-arrow {
        width: 40px;
        height: 36px;
        position: absolute;
        right: 4px;
        bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .default {
        transition: all 0.2s;
      }

      .rotate {
        transform: rotate(-180deg);
        transition: all 0.2s;
      }

      .tops-fade-enter-active {
        transition: all 0.2s ease-in-out;
      }
      .tops-fade-leave-active {
        max-height: 232px;
      }
      .tops-fade-enter,
      .tops-fade-leave-to {
        max-height: 124px;
      }
    }
  }
}
