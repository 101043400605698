@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: -5px;
}
::v-deep .van-tab {
  margin-right: 21px;
}
::v-deep .van-divider {
  margin: 0;
  margin-top: -2px;
  border-color: $DividerColor;
}
::v-deep .van-tabs__line {
  width: 20px;
}
.contents {
  .c-header {
    margin-bottom: 20px;
    border-top: 1px solid #eee;
    background-color: #fff;
    height: 48px;
    padding: 0 24px;
    box-sizing: border-box;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0 0 4px 4px;

    .c-search-done {
      margin: 0 32px 0 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .c-sort {
      color: $TextColor3rd;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .c-search {
        flex: 1;
        box-sizing: border-box;
        width: 180px;
        height: 32px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        margin-right: 32px;

        input {
          width: 108px;
          height: 12px;
          border: none;
          padding: 0;
          margin: 0;
          background-color: transparent;
          margin: 0 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $TextColor1st;

          &::-webkit-input-placeholder {
            color: $TextColor;
          }

          &:-moz-placeholder {
            color: $TextColor;
          }

          &::-moz-placeholder {
            color: $TextColor;
          }

          &:-ms-input-placeholder {
            color: $TextColor;
          }
        }
      }

      .divider {
        width: 1px;
        height: 8px;
        background: #eeeeee;
        border-radius: 1px;
        margin: 0 12px;
      }
      .active {
        color: $TextColor2nd;
      }
    }
  }

  .result-content {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }

  .game-item {
    display: block;
    cursor: pointer;
    background-color: #fff;
  }
}
