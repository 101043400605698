@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































.map-item {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #f6f6f6;
  .map-title-row {
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    margin-bottom: 16px;

    .title-mark {
      width: 4px;
      height: 17px;
      background: #3d96ff;
      margin-right: 8px;
    }
    .map-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 16px;
    }
  }
  .contents {
    display: flex;
    flex-wrap: wrap;
    .content-item {
      width: 140px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      @include ellipsis();
      margin-bottom: 8px;
      margin-right: 18px;
      .text {
        max-width: 140px;
        cursor: pointer;
        &:hover {
          color: #3d96ff;
        }
      }
    }
  }
}
