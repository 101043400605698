@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
::v-deep .van-popup {
  width: 520px;
  height: 600px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.dialog {
  .title-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 24px;
    .game-info {
      flex: 1;
      display: flex;
      align-items: center;

      .game-icon {
        width: 64px;
        height: 64px;
        img {
          width: 64px;
          height: 64px;
        }
      }

      .title-box {
        margin-left: 16px;
        .title {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 33px;
        }
        .version {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }
  }

  .list {
    height: 480px;
    overflow-y: auto;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-bottom: 4px;
      padding: 0 24px;
    }
    .item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;
      line-height: 20px;
      box-sizing: border-box;
      margin: 0 24px;

      .item-title {
        font-weight: 600;
        color: #333333;
        padding: 16px 0;
        border-bottom: 1px solid #eee;
      }
      .item-detail-ul {
        .item-detail-li {
          font-weight: 400;
          color: #666666;
          padding: 16px 0;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
