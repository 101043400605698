@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";















































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 24px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.info-card {
  margin: 0 24px 28px;
  border-radius: 4px;
  background: #ffffff;
  .plugin-desc {
    padding: 10px 0;
  }
  .info-item {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    line-height: 20px;
  }
  .more-btn {
    color: #2496ff;
    font-weight: 400;
    line-height: 18px;
  }
}
