@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































































































































































::v-deep .dplayer-played,
::v-deep .dplayer-thumb,
::v-deep .dplayer-volume-bar-inner {
  background: #2496ff !important;
}

.video-card {
  font-size: 14px;
  padding: 24px;
  .user-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .avatar-wrapper {
    width: 32px;
    height: 32px;
    position: relative;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
    .auth {
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
    }
  }
  .name {
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    line-height: 12px;
    margin: 0 7px 0 8px;
  }
  .title {
    max-width: 632px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    @include ellipsis();
    margin-bottom: 4px;
  }
  .desc {
    max-width: 632px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    @include ellipsiss(2);
  }
  .video-wrapper {
    margin-top: 20px;
    width: 632px;
    height: 356px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    .poster {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 3;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
    .player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .bottom {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #cccccc;
    .time {
      line-height: 12px;
    }
    .right {
      display: flex;
      align-items: center;
      .text {
        display: block;
        height: 16px;
        line-height: 16px;
        margin-right: 32px;
      }
      .text:last-child {
        margin-right: 0;
      }

      .icon {
        margin-right: 4px;
      }
    }
  }
}
