@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




































































































































































































































::v-deep .dplayer-played,
::v-deep .dplayer-thumb,
::v-deep .dplayer-volume-bar-inner {
  background: #2496ff !important;
}

.video-page {
  width: 1300px;
  display: flex;
  position: relative;
  .left {
    min-height: 1180px;
    width: 940px;
    .video-wrapper {
      width: 940px;
      height: 528px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      position: relative;
      .player {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
      }
    }
  }

  .right {
    margin-left: 20px;
    width: 340px;
  }
}
