@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

























































.bar-wrapper {
  position: fixed;
  left: auto;
  bottom: 0;
  z-index: 10;
  .confirm-bar {
    width: 680px;
    height: 64px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      margin-right: 24px;
    }
    .btn {
      width: 68px;
      height: 32px;
      background: #2496ff;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 32px;
      text-align: center;
      margin-right: 24px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .disabled {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        opacity: 0.4;
      }
    }
  }
  .bar-bottom {
    height: 20px;
    width: 100%;
    background-color: #f8f8f8;
  }
}
