@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































.category-name {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $TextColor1st;
      margin-right: 8px;
    }
    .num {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $TextColor3rd;
    }
  }
}
