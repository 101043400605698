@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";










































































.gh-alert {
  width: auto;
  height: 32px;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 32px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 76px;
  left: 50%;
  z-index: 10005;
  transform: translateX(-50%);
  .text {
    margin-left: 4px;
  }
}
.info {
  background: #2c3a4d;
}
.error {
  background: #f55d71;
}
.success {
  background: #15d6d6;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
