@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

























































::v-deep .van-popup {
  width: 280px;
  height: 190px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 0;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: $TextColor1st;
  }
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  .text {
    height: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 18px;
  }
  .tip {
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}

element::-webkit-scrollbar {
  display: none;
}
