@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































.juhe-item {
  margin-left: 24px;
  height: 16px;
  margin-bottom: 12px;
}

.li-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
a {
  color: #333333;
  max-width: 294px;
  display: block;
  font-size: 14px;
  line-height: 16px;
  @include ellipsis();
}
.time {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.6);
  line-height: 16px;
}
