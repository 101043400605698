@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";












































































































html {
  font-size: 14px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Microsoft YaHei,
    PingFang SC, miui, -apple-system, BlinkMacSystemFont, Segoe UI Emoji,
    Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
  min-width: 1350px;
}
