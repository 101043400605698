@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















































































































.footer-component {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 120px;
  background: #2c3a4d;
  padding-bottom: 24px;
  .footer-main {
    margin: 0 auto;
    width: 1040px;
    height: 100%;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);

    .left {
      margin-right: 109px;
    }
    .info {
      cursor: pointer;
      a {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .title {
      margin-top: 24px;
      width: 68px;
      height: 16px;
    }
    .icp {
      display: inline-block;
      margin: 24px 24px 8px 0;
    }
    .right {
      flex: 1;
      .title {
        width: 64px;
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 20px;
        line-height: 22px;
      }
      .links {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        .link {
          a {
            color: rgba(255, 255, 255, 0.6);
            width: 48px;
            line-height: 17px;
            width: 130px;
            margin-bottom: 4px;
            padding-right: 34px;
            box-sizing: border-box;
            cursor: pointer;
            display: inline-block;
            @include ellipsis();
          }
        }
      }
      .other {
        display: flex;
        margin-top: 24px;
        cursor: pointer;
        a {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
