@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























































.game-recommend {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  .title {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid #eeeeee;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .list {
    width: 340px;
    padding: 8px 0;
  }
  .recommend-item {
    height: 80px;
    padding: 16px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    overflow: hidden;
    margin-right: 16px;
  }
  .name {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 4px;
    line-height: 20px;
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
}
